<template>
    <v-container style="max-width:100vw;">
        <v-card class="elevation-0 px-12 py-6">

            <v-radio-group v-model="factura" class="mb-4">
                <v-radio label="Remisión" color="primary" value="remission"></v-radio>
                <v-radio label="Factura" color="primary" value="invoice"></v-radio>
            </v-radio-group>

            <!-- # factura o remisión-->
            <v-text-field v-model="editedItem.invoice" v-show="factura=='invoice'" label="Factura Macro" rounded outlined prefix="#"></v-text-field>
            <v-text-field v-model="editedItem.remission" v-show="factura=='remission'" label="Remisión Macro" rounded outlined prefix="#"></v-text-field>

            <!-- Moneto de Ticket -->
            <v-text-field v-model="editedItem.amount" label="Monto Ticket" rounded type="number" outlined prefix="$"></v-text-field>

            <!-- Metodos de Pago e Importes -->
            <v-card class="elevation-0">
              <!-- Titulo -->
              <v-card-title class="mb-2">
                <span class="headline">Metodo de Pago</span>
              </v-card-title>
                <v-card-text class="pb-0">
                    <v-container>
                    <!-- Formulario -->
                        <v-row class="form-group py-2" v-for="(method,k) in editedItem.methods" :key="k">
                            <v-col cols="5" class="py-0 my-0">
                                <v-select :items="methodList" v-model="method.method" rounded outlined label="Metodo de Pago" item-text="method" item-value="id"></v-select>
                            </v-col>
                            <v-col cols="4" class="py-0 my-0">
                                <v-text-field v-model="method.recibido" prefix="$" type="number" label="Importe de Pago"></v-text-field>
                            </v-col>
                            <v-col cols="2">
                                <v-icon @click="remove(k)" v-show="k || ( !k && editedItem.methods.length > 1)" color="red">mdi-close</v-icon>
                                <v-icon @click="add(k)" v-show="k == editedItem.methods.length-1" color="primary">mdi-plus</v-icon>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>

            <!-- Guardar -->
            <v-card-actions>
                <v-card-subtitle class="pb-0" style="font-size:30px!important; line-height:55px;">
                    <strong>Por Pagar = {{(editedItem.amount*1).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}</strong><br>
                    <strong>Pagado = {{(pagado*1).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}</strong><br>
                    <strong v-if="cambio>0">Cambio = {{(cambio*1).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}</strong>
                    <strong v-else>Faltan = {{(cambio*-1).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}</strong>
                </v-card-subtitle>
                <v-spacer></v-spacer>
                <v-btn color="primary" class="pa-12 elevation-0" :loading="gris" :disabled="gris" style="font-size:25px!important;" @click="save">Guardar</v-btn>
            </v-card-actions>
        </v-card>
        <v-snackbar :color="snackbar.color" v-model="snackbar.show">
            {{ snackbar.message }}
        </v-snackbar>
    </v-container>
</template>

<script>
import axios from "axios";
export default {
    data () {
        return {
            factura:'',
            editedItem: {
                macro:true,
                date:new Date(),
                payment_method_id:'',
                amount:'',
                invoice:'',
                note:'',
                pdf:'',
                created_by_user_id:'',
                last_updated_by_user_id:'',
                user_id:'',
                company_id:'',
                remission:'',
                salesID:[],
                methods:[{
                    method:'',
                    amount:0,
                    recibido:0
                }]
            },
            gris:false,
                snackbar: {
                show: false,
                message: null,
                color: null
            },
        }
    },
    computed:{
        methodList:{
            get(){
                return this.$store.state.payment_method.payment_methods
            }
        },
        cambio(){
            return  this.pagado - (this.editedItem.amount*1)
        },
        pagado(){
            var sum = 0
            this.editedItem.methods.forEach(e => {
                sum += (Number(e.recibido));
            });
            return  sum
        },
        currentUser:{
            get(){
                return this.$store.state.currentUser.user
            }
        }
    },
    methods: {
        lowerCase(text){
            if(text!=null&&text!=undefined&&text!=''){
                return text.toLowerCase()
            }else{
                return ' '
            }
        },
        paymentMethod(id){
            return this.$store.state.payment_method.payment_methods.filter(method=>method.id == id).map(method=>method.method)[0]
        },
        add(index) {
            var sum = 0
            var resultado = 0
            resultado = this.cambio*-1
            this.editedItem.methods.push({ method: '', recibido: resultado.toFixed(2) });
        },
        remove(index) {
            this.editedItem.methods.splice(index, 1);
        },
        save(){
            console.log(this.factura)
            console.log(this.editedItem.remission)
            console.log(this.editedItem.invoice)
            console.log('--------')
            console.log(this.factura == 'invoice')
            console.log(this.factura == 'remission')
            if(this.factura == 'invoice'){
                this.editedItem.remission = ''
            }
            if(this.factura == 'remission'){
                this.editedItem.invoice = ''
            }
            console.log('--------')
            console.log(this.factura)
            console.log(this.editedItem.remission)
            console.log(this.editedItem.invoice)


            for(var i=0; i<this.editedItem.methods.length; i++){
                if(this.lowerCase(this.paymentMethod(this.editedItem.methods[i].method)) == 'efectivo'){
                    this.editedItem.methods[i].amount = (this.editedItem.methods[i].recibido*1) - (this.cambio*1)
                }else{
                    this.editedItem.methods[i].amount = this.editedItem.methods[i].recibido
                }
            }
            this.gris = true
            this.editedItem.created_by_user_id = this.currentUser.id
            this.editedItem.last_updated_by_user_id = this.currentUser.id
            this.editedItem.user_id = this.currentUser.id
            this.editedItem.date = this.editedItem.date.toISOString().slice(0, 10)
            this.$nextTick(() => {
                axios.post("https://intenbackend.unocrm.mx/api/v1/collection/create",Object.assign(this.editedItem)).then(response=>{
                    location.reload();
                }).catch(error => {
                    this.snackbar = {
                        message: error.response.data.message,
                        color: 'error',
                        show: true
                    }
                    this.gris = false
                })
            })
        },
    }
}
</script>

<style>
.v-label, .v-input {
    font-size: 23px!important;
}
</style>